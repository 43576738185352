import React from "react"

import Row from "./row"
import SectionTitle from "./sectionTitle"
import Image from "./image"

import l from "./layout.module.scss"

const ClientsSection = ({ data, ...rest }) => {
  const { title, logos } = data

  // Check whether for dummy logos. In that case don't render altogether
  const dummyLogoIndex = logos.findIndex(l => l.url === "dummylogo")

  if (dummyLogoIndex === -1) {
    return (
      <Row {...rest}>
        <SectionTitle title={title} />
        <div className={l.grid_3}>
          {logos.map(i => (
            <a key={i.url} href={i.url}>
              <Image data={i.logo} />
            </a>
          ))}
        </div>
      </Row>
    )
  } else return null
}

export default ClientsSection
