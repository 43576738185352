import React from "react"

import CTAButton from "./cta_button"
import Image from "./image"

import l from "./layout.module.scss"

const Card = ({ data }) => {
  const { title, text, image, label = "" } = data
  return (
    <div key={title} className={l.card}>
      <Image data={image} style={{ marginBottom: "1rem" }} />
      <h5 style={{ marginBottom: label ? "1rem" : "2rem" }}>{title}</h5>
      <div
        className={label ? l.above_link : l.text_block}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <CTAButton data={data} />
    </div>
  )
}

export default Card
