import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../components/fragments/index"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "../components/row"
import Arrow from "../components/arrow"
import Spacer from "../components/spacer"
import SectionTitle from "../components/sectionTitle"
import Card from "../components/card"
import Button from "../components/button"
import ClientsSection from "../components/clients_section"
import Slider from "../components/slider"
import Image from "../components/image"

import l from "../components/layout.module.scss" // layout styles
import i from "./index.module.scss" // home styles

const IndexPage = () => {
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [height, setHeight] = useState(null)
  const [windowWidth, setWindowWidth] = useState(0)
  // If a user drags the products no click event should be fired
  const [dragging, setDragging] = useState(false)
  const [mousePos, setMousePos] = useState({})

  const productGrid = useRef()
  const singleProduct = useRef()

  useEffect(() => {
    // since window is not available during build, run this code at componentDidMount hook
    setWindowWidth(window.innerWidth)
  }, [])

  const { home, machines: products, technologies } = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "home" } }) {
        edges {
          node {
            ...homepageFragment
          }
        }
      }
    }
  `).allWordpressPage.edges[0].node.acf

  const handleMouseDown = evt => {
    setMousePos({ x: evt.clientX, y: evt.clientY })
  }
  const handleMouseUp = (evt, product) => {
    if (dragging) return
    if (mousePos.x === evt.clientX && mousePos.y === evt.clientY) {
      handleSelectedProduct(product)
      setMousePos({})
    }
  }

  const handleSelectedProduct = product => {
    if (dragging) return
    setHeight(`${productGrid.current.getBoundingClientRect().height}px`)
    // 1. Fade out product grid and show product details
    setSelectedProduct(product)
  }

  const getProductOverview = () => {
    // 1. Fade out product details
    singleProduct.current.classList.remove("show")
    // 2. After 300ms remove component and show product overview
    const timeout = setTimeout(() => {
      setSelectedProduct(null)
      setHeight(null)
      window.clearTimeout(timeout)
    }, 200)
  }

  const getProductSlug = productName => {
    return productName
      .toLowerCase()
      .replace(/limach\s/g, "")
      .replace(".", "-")
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Row backgroundColor="#f2f2f2">
        <div className={[l.col1, l.title_section, i.title_section].join(" ")}>
          <h1 className={l.title}>{home.hero_title}</h1>
          <h3 className={l.sub_title}>{home.hero_subtitle}</h3>
        </div>
        <div ref={productGrid} className={i.product_container}>
          <div
            className={[i.product_grid, selectedProduct ? i.active : null].join(
              " "
            )}
          >
            <div className={i.grid_lines}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <Slider
              options={{
                perView: 3,
                keyboard: true,
                bound: true,
                perTouch: 1,
                dragThreshold: 80,
                rewind: false,
                breakpoints: {
                  850: {
                    perView: 2,
                  },
                  600: {
                    perView: 1,
                  },
                },
              }}
              draggingHandler={setDragging}
              maxHeight="auto"
              color="rgba(46, 46, 46, 0.6)"
            >
              {products.map((p, index) => {
                return (
                  <div
                    onMouseDown={e => handleMouseDown(e)}
                    onMouseUp={e => handleMouseUp(e, p)}
                    key={p.product_name}
                    className={i.product}
                    // role="button"
                    // tabIndex="0"
                  >
                    <Image
                      data={p.image}
                      style={{
                        margin:
                          windowWidth < 600
                            ? `${index > 0 ? "2rem" : "0"} 0 4rem 0`
                            : "4rem 0",
                      }}
                    />
                    <div className={i.product_caption}>
                      <span className={i.product_title}>{p.product_name}</span>
                      <span className={i.caption_subtitle}>{p.title}</span>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
          {selectedProduct && (
            <div
              style={{
                marginTop: `-${height}`,
              }}
              className={[
                l.col1,
                l.section_grid_05,
                "product_details",
                selectedProduct && "show",
              ].join(" ")}
              ref={singleProduct}
            >
              <div className="image">
                <figure>
                  <Image data={selectedProduct.image} />
                  <figcaption> {selectedProduct.product_name}</figcaption>
                </figure>
              </div>
              <div className="details">
                <div className="product_heading">{selectedProduct.title}</div>
                <p className={l.text_block}>{selectedProduct.description}</p>
                <div className={l.button_container}>
                  <Button
                    color="black"
                    type={{
                      tag: "link",
                      url: `/producten/${getProductSlug(
                        selectedProduct.product_name
                      )}`,
                    }}
                  >
                    Ontdek
                  </Button>
                  <Button>
                    <Arrow
                      clickHandler={getProductOverview}
                      fontSize="18px"
                      fontWeight="bold"
                    >
                      Ga terug
                    </Arrow>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Spacer>
          <Arrow rotation="90deg" extraClass={l.hide_on_mobile} />
        </Spacer>
      </Row>
      <Row backgroundImage={home.achtergrond}>
        <Spacer height="35rem" />
      </Row>
      <Row>
        <SectionTitle title="Waarin deze innovatieve Limach techniek zich onderscheidt." />
        <div className={l.col1}>
          <div className={i.technology_grid}>
            {technologies.map(item => (
              <Card key={item.title} data={item} />
            ))}
          </div>
        </div>
      </Row>
      <Row backgroundColor="#f2f2f2" extraClasses={[i.about_section]}>
        <SectionTitle title={home.about_title} />
        <div className={l.col1}>
          <div className={l.section_grid_2}>
            <div className={i.about_text}>
              <div
                dangerouslySetInnerHTML={{
                  __html: home.about_text,
                }}
              ></div>
              <Link
                className={[l.button_arrow, l.above_image].join(" ")}
                to={home.about_url}
              >
                <Arrow fontSize="18px" fontWeight="bold">
                  {home.about_label}
                </Arrow>
              </Link>
            </div>
            <Image data={home.about_image} />
          </div>
        </div>
        <Spacer height="4rem" />
      </Row>
      <Row>
        <div className={l.col1}>
          <div className={l.section_grid_3}>
            {home.about_blocks.map(blockItem => (
              <Card key={blockItem.title} data={blockItem} />
            ))}
          </div>
        </div>
      </Row>
      <ClientsSection
        extraClasses={[i.clients_section]}
        backgroundColor="#f2f2f2"
        data={home.references}
      />
    </Layout>
  )
}

export default IndexPage
